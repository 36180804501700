import $ from 'jquery';

var DpMailForm = function (modalClass, successModalClass, submitBtnClass, url, alreadySendFn, sucFn, flFn, metricFn) {

  var self = this;
  this.modalClass = '.' + modalClass;
  this.successModalClass = '.' + successModalClass;
  this.submitBtnClass = '.' + submitBtnClass;
  this.url = url;
  this.$modal = $(this.modalClass);
  this.$successModal = $(this.successModalClass);
  this.successFn = sucFn;
  this.alreadySendFn = alreadySendFn;
  this.failFn = flFn;
  this.metricFn = metricFn;
  this.$timeModal = null;

  this.$modal.find('form').submit('click', function (e) {
    e.preventDefault();
    self.sendMail($(this));
    return false;
  });
};

DpMailForm.prototype.sendMail = function ($obj) {
  var self = this;

  var $modal = $obj.closest(self.modalClass),
    fullModalName = $modal.attr('id'),
    modalName = fullModalName.replace('modal', '');

  if ($.cookie(fullModalName)) {
    self.alreadySendFn(self, $modal);
  } else {
    var data = {};
    self.$timeModal = $modal;
    // can be extended
    self.processMail($modal, modalName, data);

    data['title'] = $modal.attr('title');
    data['type'] = $modal.attr('type');
    // utm
    data['utm_source'] = self.getParameterByName('utm_source') || '';
    data['utm_term'] = self.getParameterByName('utm_term') || '';

    // send mail
    $.ajax({
      url: self.url,
      type: 'POST',
      dataType: 'json',
      data: {'data': data},
      encode: true,
      success: function (data) {
        if (self.successFn) {
          self.successFn(self, data);
        }

        $.cookie(fullModalName, true);

        if (self.metricFn) {
          self.metricFn(self, $modal);
        }
      },
      error: function (err) {
        if (self.failFn) {
          self.failFn(self, err);
        }
      }
    });
  }
};

DpMailForm.prototype.getParameterByName = function (name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

DpMailForm.prototype.processMail = function ($modal, modalName, data) {
  $modal.find('input,textarea').each(function () {
    var value;
    if ($(this).hasClass('select-dropdown')) {
      var $select = $(this).closest('.select-wrapper').find('select');
      value = $(this).val();
      data[$select.attr('id').replace(modalName, '')] = value;
    } else {
      data[$(this).attr('id').replace(modalName, '')] = $(this).val();
    }
  });
};

$(document).ready(function () {
    var presModal = $('#callback-modal');

    new DpMailForm('form-modal', 'success-modal', 'form-btn', 'mail.php',
        alreadySend, success, fail, metric);

    function alreadySend(self, $modal) {
        try {
            self.$timeModal.modal('hide');
        } catch (e) {
        }
        self.$successModal.find('.text-message').text('Вы уже отправили заявку');
        var sucDialog = self.$successModal;
        sucDialog.modal('show');
        setTimeout(function () {
            sucDialog.modal('hide');
        }, 2000);
    }

    function success(self, data) {
        try {
            self.$timeModal.modal('hide');
            if (self.$timeModal[0] == presModal[0]) {
                openInNewTab('/presentation.pdf')
            }
        } catch (e) {
        }
        self.$successModal.find('.text-message').text('Заявка отправлена! Наши менеджеры свяжутся с вами!');
        var sucDialog = self.$successModal;
        sucDialog.modal('show');
        setTimeout(function () {
            sucDialog.modal('hide');
        }, 2000);
    }

    function fail(self, $modal) {
    }

    function metric(self, $modal) {
        //yaCounter43620329.reachGoal($modal.attr('type'));
    }

    function openInNewTab(url) {
        location.href = url;
    }
});



