import $ from 'jquery';
import './jquery.cookie.js';
import 'bootstrap/dist/js/bootstrap.bundle';
import * as vanillaTextMask from 'vanilla-text-mask';
import SmoothScroll from './smooth-scroll.polyfills.min';
import './scrollTop';
import './fontawesome.js';
import './init';

$(window).on('load', function() {
  $('#preloader').removeClass('active');
  $('body').removeClass('body-overflow');
});

$(document).ready(function() {
  var year = document.getElementById('copyright-year');
  if (year) {
    year.innerHTML = new Date().getFullYear();
  }
  $('.slideout-link').click(function() {
    $('body').toggleClass('body-overflow');
    $('#slideout').removeClass('slideout-opened');
    $('#hamburger-btn').removeClass('is-active');
    $('#hamburger-btn').removeClass('opened');
  });

  var scroll = new SmoothScroll('a[href*="#"]', {
    easing: 'easeInOutCubic',
    speed: 1200,
  });

  var phoneMask = ['+', /\d/, '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
  vanillaTextMask.maskInput({
    inputElement: document.querySelector('#phone-input'),
    mask: phoneMask,
    placeholderChar: '_',
    guide: true,
  });

  $('.audit-btn').click(function() {
    $('#questions-modal').modal();
  });

  $('.navigation-btn').click(function() {
    $('#questions-modal').modal();
  });

  $('#hamburger-btn').click(function() {
    $('body').toggleClass('body-overflow');
    $('#slideout').toggleClass('slideout-opened');
    $('#hamburger-btn').toggleClass('opened');
    if (!$('#hamburger-btn').hasClass('is-active')) {
      $('#hamburger-btn').addClass('is-active');
    } else {
      $('#hamburger-btn').removeClass('is-active');
    }
  });

  ymaps.ready(init);

  function init() {
    var myMap = new ymaps.Map('map', {
      // Координаты центра карты.
      // Порядок по умолчанию: «широта, долгота».
      // Чтобы не определять координаты центра карты вручную,
      // воспользуйтесь инструментом Определение координат.
      center: [59.934298, 30.335100],
      // Уровень масштабирования. Допустимые значения:
      // от 0 (весь мир) до 19.
      zoom: 7,
    });
    myMap.controls.remove('searchControl');
    myMap.controls.remove('geolocationControl');
    myMap.controls.remove('trafficControl');
    myMap.controls.remove('typeSelector');
    myMap.controls.remove('fullscreenControl');
    myMap.controls.remove('rulerControl');
    myMap.behaviors.disable('scrollZoom');
    myMap.geoObjects
      .add(
        new ymaps.Placemark(
          [59.944213, 30.378447],
          {
            balloonContent: 'Ул. Таврическая, 17',
          },
          {
            preset: 'islands#redIcon',
            iconColor: '#0095b6',
          }
        )
      )
      .add(
        new ymaps.Placemark(
          [56.129007, 40.393016],
          {
            balloonContent: 'Октябрьский проспект, 7',
          },
          {
            preset: 'islands#redIcon',
            iconColor: '#0095b6',
          }
        )
      );
  }
});
