import fontawesome from '@fortawesome/fontawesome';
import faMap from '@fortawesome/fontawesome-pro-light/faMap';
import faDesktopAlt from '@fortawesome/fontawesome-pro-regular/faDesktopAlt';
import faMapMarkerAlt from '@fortawesome/fontawesome-pro-light/faMapMarkerAlt';
import faCogs from '@fortawesome/fontawesome-pro-light/faCogs';
import faCog from '@fortawesome/fontawesome-pro-light/faCog';
import faPhone from '@fortawesome/fontawesome-pro-light/faPhone';
import faChevronUp from '@fortawesome/fontawesome-pro-regular/faChevronUp';

fontawesome.library.add(faMap, faMapMarkerAlt, faCogs, faCog, faPhone, faChevronUp,  faDesktopAlt );
